<template>
  <div class="bg-white">
    <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      

      <div class="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
        <div v-for="product in AllProducts" :key="product.id" class="group relative">
          <div class="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 lg:h-72 xl:h-80">
            <img :src="product.get_image" class="w-full h-full object-center object-cover" />
          </div>
          <h3 class="mt-4 text-sm text-gray-700">
            <router-link :to="product.get_absolute_url">
              <span class="absolute inset-0" />
              {{ product.name }}
            </router-link>
          </h3>
          <p class="mt-1 text-sm text-gray-500">{{ product.color }}</p>
          <p class="mt-1 mb-8 text-sm font-medium text-gray-900">${{ product.price }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid'

export default {
  name: 'AllProducts',
  data(){
    return { 
      AllProducts: [],
    }
  },
  components: {
      ArrowNarrowLeftIcon,
      ArrowNarrowRightIcon,
  },
  mounted() {
    this.getAllProducts() 
  },
  methods: {
    async getAllProducts() {
      this.$store.commit('setIsLoading', true)
     await axios.get(`/api/v1/products/`)
      .then(response => {
        
        this.AllProducts = response.data.results
      })
      .catch(err => {
    
      })
      this.$store.commit('setIsLoading', false)
    } 
  }
}
</script>